html, body{
  font-size: 16px;
  font-family: 'Fakt Soft Pro','Helvetica Neue', Arial, Helvetica, sans-serif;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

